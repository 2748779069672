<div *ngIf="liveEventDetails$ | async as liveEvent; else loadingAnimation" class="page-container" [ngClass]="{ expanded: isExpanded }">
    <div class="page-content">
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 class="ellipsis">
                    <button type="button" class="btn btn-round me-1 back-button" (click)="redirectToLiveEventsList()" title="{{ 'BACK' | translate }}">
                        <fa-icon icon="chevron-left" size="md"></fa-icon></button
                    ><zx-status-icon
                        *ngIf="liveEvent.liveEvent.generalStatus"
                        class="d-inline-block"
                        [model]="liveEvent.liveEvent"
                        size="sm"
                        class="me-1"
                    ></zx-status-icon
                    >{{ liveEvent.liveEvent.name }}
                </h1>
            </div>
            <div class="actions">
                <div class="form-inline justify-content-end align-items-baseline">
                    <fa-icon
                        class="me-2 tourStartButton"
                        icon="question-circle"
                        #tooltip="matTooltip"
                        matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_LIVE_EVENT_DISPLAY_BY_CLICKING_HERE' | translate }}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        size="lg"
                        (click)="tourService.start(); mixpanelService.sendEvent('start live event display guide')"
                    ></fa-icon>
                    <!-- Refresh -->
                    <button type="button" class="btn btn-outline-secondary mb-2 ms-2" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="liveEventDetailsService.isFetchingData$ | async"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "REFRESH" | translate }}</span>
                    </button>
                    <!-- Expand -->
                    <button type="button" class="btn btn-outline-secondary mb-2 ms-2" (click)="expand()" title="{{ 'EXPAND' | translate }}" *ngIf="!isExpanded">
                        <fa-icon icon="expand" size="sm"></fa-icon>
                        <span class="sr-only">{{ "EXPAND" | translate }}</span>
                    </button>
                    <!-- Contract -->
                    <button
                        type="button"
                        class="btn btn-outline-secondary mb-2 ms-2"
                        (click)="contract()"
                        title="{{ 'CONTRACT' | translate }}"
                        *ngIf="isExpanded"
                    >
                        <fa-icon icon="compress" size="sm"></fa-icon>
                        <span class="sr-only">{{ "CONTRACT" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="content-area pt-0 pb-0 d-flex flex-column">
            <div class="px-3 py-2 align-items-center flex-0">
                <!-- Event Stages Timeline -->
                <div class="d-flex flex-wrap">
                    <div class="d-flex flex-wrap flex-row align-items-center">
                        <div>
                            <!-- Event Status -->
                            <h2
                                tourAnchor="firstAnchor"
                                [ngClass]="{
                                    active: !activeActionName || activeActionName === 'off'
                                }"
                            >
                                {{ (activeActionName === "off" ? "EVENT_COMPLETE" : "EVENT_SCHEDULED") | translate }}
                            </h2>
                            <!-- Next Stage Timer -->
                            <!-- TODO: AMIT check it to keep this du to remove of  staging_mode-->
                            <!-- Before there was && liveEvent.liveEvent.staging_mode === 'auto' also in the ngIf -->
                            <span *ngIf="activeActionName !== 'off'" tourAnchor="secondAnchor">
                                {{ "NEXT_STAGE_IN" | translate }} <strong>{{ (countdownTime$ | async) ?? "00:00:00" }}</strong></span
                            >
                        </div>
                    </div>
                    <div class="stage-bar" tourAnchor="thirdAnchor">
                        <ng-container *ngFor="let action of liveEvent.actions; let i = index">
                            <!-- Clip bar item -->
                            <div
                                *ngIf="action.clipName"
                                class="stage-bar-item"
                                [style]="{ width: 100 / (liveEvent.actions.length + numberOfActiveClips) + '%' }"
                                [ngClass]="{
                                    past: liveEvent.liveEvent.stage_id === action.id || isPastAction(liveEvent, i)
                                }"
                            >
                                <div class="circle p-0">
                                    <fa-icon icon="video" size="sm"></fa-icon>
                                </div>
                                <h3 class="name" *ngIf="action.clipName">{{ action.clipName | titlecase }}</h3>
                            </div>

                            <!-- Stage bar item -->
                            <div
                                class="stage-bar-item"
                                [style]="{ width: 100 / (liveEvent.actions.length + numberOfActiveClips) + '%' }"
                                [ngClass]="{
                                    active: liveEvent.liveEvent.stage_id === action.id,
                                    past: isPastAction(liveEvent, i)
                                }"
                            >
                                <div class="circle">
                                    <fa-icon *ngIf="liveEvent.liveEvent.stage_id === action.id && action.name !== 'off'" icon="play" size="sm"></fa-icon>
                                    <fa-icon *ngIf="action.name === 'off'" icon="stop" size="sm"></fa-icon>
                                </div>
                                <h3 class="name">
                                    {{ action.name | titlecase }}
                                </h3>
                                <!-- TODO: AMIT check it to keep this du to remove of  staging_mode-->
                                <!-- Before there was && liveEvent.liveEvent.staging_mode === 'auto' also in the ngIf -->
                                <div>
                                    <span *ngIf="!moment().isSame(action.scheduled_time, 'day')">
                                        <zx-date-time-zone [dateTime]="action.scheduled_time" [dateFormat]="dateFormats.longDate" />,</span
                                    ><span><zx-date-time-zone [dateTime]="action.scheduled_time" [dateFormat]="dateFormats.timeSeconds" /> </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- Event Control Actions -->
            <div class="actionButtons" *ngIf="activeActionName !== 'off'">
                <div class="btn-group mb-0" tourAnchor="fourthAnchor">
                    <ng-container *ngFor="let dashboardAction of dashboardActions">
                        <button
                            *ngIf="!dashboardAction.hide()"
                            class="btn btn-outline-primary"
                            [disabled]="dashboardAction.isDisable()"
                            (click)="triggerDashboardAction(dashboardAction.name, liveEvent)"
                            title="{{ dashboardAction.getTitle(liveEvent) }}"
                            tourAnchor="{{ dashboardAction.name }}"
                        >
                            <fa-icon [icon]="dashboardAction.getIcon().icon" [size]="dashboardAction.getIcon().size"></fa-icon
                            ><span class="d-none d-sm-inline-block ms-1">{{ dashboardAction.getTitle(liveEvent) }}</span>
                            <span *ngIf="dashboardAction.isLoading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </ng-container>
                </div>
            </div>

            <!-- Bottom Panel -->
            <div class="bottom-panel">
                <!-- Channels -->
                <div class="left-contain" tourAnchor="ninthAnchor">
                    <div *ngFor="let failoverChannel of liveEvent.failoverChannels; let fcIndex = index; trackBy: trackById" class="failover-channel">
                        <div class="row">
                            <div class="col-12 col-xxl-9 d-flex flex-column">
                                <h4>{{ "CHANNEL" | translate }}</h4>
                                <div class="row">
                                    <div class="col">
                                        <div class="sub-content d-flex flex-column justify-content-center">
                                            <div class="d-flex flex-row justify-content-between">
                                                <h5 class="ellipsis me-1">
                                                    <zx-failover-channel
                                                        [model]="failoverChannel.data"
                                                        [showStatusIcon]="true"
                                                        [showStatusText]="false"
                                                        [showLink]="false"
                                                        [showTag]="false"
                                                    ></zx-failover-channel>
                                                </h5>
                                                <a
                                                    [routerLink]="['/' + urls.channels, 'failover', failoverChannel.data.id | uId, failoverChannel.data.name]"
                                                    target="_blank"
                                                    ><fa-icon icon="external-link" size="sm"></fa-icon
                                                ></a>
                                            </div>
                                            <app-source-thumbnail
                                                [source]="failoverChannel.sources.failover"
                                                [overlay]="true"
                                                [info]="false"
                                                [bordered]="false"
                                                [bitrateOverlay]="true"
                                            ></app-source-thumbnail>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2 col-xxl-3 mt-xxl-0 d-flex flex-column">
                                <h4>{{ "SOURCES" | translate }}</h4>
                                <div class="flex-grow-1 d-flex row flex-xxl-column">
                                    <div
                                        class="col-12 col-sm-6 col-xl-4 col-xxl-12 mb-2"
                                        *ngFor="let source of failoverChannel.sources.sources; let sourceIndex = index"
                                    >
                                        <div
                                            class="sub-content d-flex flex-column justify-content-center position-relative"
                                            [ngClass]="{ inactive: !source?.statusFromCompoundComponent?.is_active }"
                                            (mouseover)="hoveringOn = 'source_' + source.id"
                                            (mouseleave)="hoveringOn = null"
                                            id="source_{{ source.id }}"
                                        >
                                            <app-source-details-wrapper
                                                [source]="source"
                                                [secondRow]="true"
                                                [priority]="
                                                    failoverChannel.sources?.slate?.id === source.id
                                                        ? 0
                                                        : failoverChannel.sources?.secondary?.id === source.id
                                                        ? 1
                                                        : 2
                                                "
                                                [locked]="failoverChannel.lockedSource === source.id"
                                                [titlePlaceholder]="'PRIMARY_SOURCE' | translate"
                                            ></app-source-details-wrapper>
                                            <div
                                                class="row flex-row modal-container"
                                                [ngStyle]="{
                                                    'margin-top.px': 20
                                                }"
                                                *ngIf="hoveringOn === 'source_' + source.id"
                                            >
                                                <div class="modal-actions btn-group btn-group-sm">
                                                    <button
                                                        *ngIf="failoverChannel.lockedSource !== source.id"
                                                        type="button"
                                                        class="btn btn-sm btn-outline-secondary w-auto"
                                                        title="{{ 'LOCK' | translate }}"
                                                        (click)="toggleSourceLock(source, failoverChannel, true)"
                                                    >
                                                        <fa-icon icon="lock" size="sm" class="me-1"></fa-icon>{{ "LOCK" | translate
                                                        }}<span
                                                            *ngIf="lockToggleLoading"
                                                            class="spinner-border spinner-border-sm ms-1"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    </button>
                                                    <button
                                                        *ngIf="failoverChannel.lockedSource === source.id"
                                                        type="button"
                                                        class="btn btn-sm btn-outline-secondary w-auto"
                                                        title="{{ 'UNLOCK' | translate }}"
                                                        (click)="toggleSourceLock(source, failoverChannel, false)"
                                                    >
                                                        <fa-icon icon="unlock" size="sm" class="me-1"></fa-icon>{{ "UNLOCK" | translate
                                                        }}<span
                                                            *ngIf="lockToggleLoading"
                                                            class="spinner-border spinner-border-sm ms-1"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-outline-secondary w-auto"
                                                        title="{{ 'PREVIEW' | translate }}"
                                                        (click)="previewedClip = null; previewSource(source)"
                                                    >
                                                        <fa-icon icon="eye" size="sm" class="me-1"></fa-icon>{{ "PREVIEW" | translate }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clips-container" *ngIf="liveEvent.liveEvent.clips.length" tourAnchor="clipsAnchor">
                        <!-- Clips here -->
                        <h4>{{ "CLIPS" | translate }}</h4>
                        <div class="clip-contain">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-xl-4 col-xxl-3" *ngFor="let clip of liveEvent.liveEvent.clips">
                                    <div
                                        class="justify-content-center sub-content mb-2 position-relative"
                                        (mouseover)="hoveringOn = 'clip_' + clip.name"
                                        (mouseleave)="hoveringOn = null"
                                        id="clip_{{ clip.name }}"
                                    >
                                        <app-source-details-wrapper
                                            [icon]="null"
                                            [source]="clip.source"
                                            [titlePlaceholder]="'CLIP' | translate"
                                            [name]="clip.name"
                                        >
                                        </app-source-details-wrapper>
                                        <div
                                            class="row flex-row modal-container"
                                            *ngIf="hoveringOn === 'clip_' + clip.name && liveEvent.liveEvent.stage_id && activeActionName !== 'off'"
                                        >
                                            <div class="modal-actions btn-group btn-group-sm">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-outline-secondary w-auto"
                                                    title="{{ 'INSERT' | translate }}"
                                                    (click)="insertClip(clip.id, liveEvent)"
                                                >
                                                    <fa-icon icon="file-import" size="sm" class="me-1"></fa-icon>{{ "INSERT" | translate
                                                    }}<span
                                                        *ngIf="insertToggleLoading"
                                                        class="spinner-border spinner-border-sm ms-1"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-outline-secondary w-auto"
                                                    title="{{ 'PREVIEW' | translate }}"
                                                    (click)="previewedClip = clip; previewedSource = null"
                                                >
                                                    <fa-icon icon="eye" size="sm" class="me-1"></fa-icon>{{ "PREVIEW" | translate }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="right-contain" tourAnchor="previewAnchor">
                    <div *ngIf="!previewedClip && previewedSource" class="preview-container position-relative" id="previewedSource_{{ previewedSource.id }}">
                        <!-- Preview here -->
                        <div class="d-flex flex-row justify-content-between">
                            <h4>{{ "PREVIEW" | translate }}</h4>
                            <div class="form-group mb-0">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="show_overlay" name="show_overlay" [(ngModel)]="showOverlay" />
                                    <label class="form-check-label" for="show_overlay">{{ "SHOW_OVERLAY" | translate }}</label>
                                </div>
                            </div>
                        </div>
                        <app-source-details-wrapper
                            [source]="previewedSource"
                            class="d-block sub-content"
                            icon="broom-wide"
                            iconTitle="Clear"
                            (iconClicked)="previewedSource = null; previewedClip = null"
                            [titlePlaceholder]="'NO_PREVIEW_SELECTED' | translate"
                            [overlay]="showOverlay"
                            [allowLivePlay]="true"
                        ></app-source-details-wrapper>
                    </div>
                    <div class="preview-container position-relative" *ngIf="previewedClip && !previewedSource" id="previewedClip_{{ previewedClip.id }}">
                        <div class="d-flex flex-row justify-content-between">
                            <h4>{{ "PREVIEW" | translate }}</h4>
                            <div class="form-group mb-0">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="show_overlay" name="show_overlay" [(ngModel)]="showOverlay" />
                                    <label class="form-check-label" for="show_overlay">{{ "SHOW_OVERLAY" | translate }}</label>
                                </div>
                            </div>
                        </div>
                        <app-source-details-wrapper
                            [source]="previewedClip.source"
                            class="d-block sub-content"
                            icon="broom-wide"
                            iconTitle="Clear"
                            (iconClicked)="previewedSource = null; previewedClip = null"
                            [name]="previewedClip.name"
                            [overlay]="showOverlay"
                        ></app-source-details-wrapper>
                    </div>
                    <div class="preview-container" *ngIf="!previewedClip && !previewedSource">
                        <h4>{{ "PREVIEW" | translate }}</h4>
                        <app-source-details-wrapper
                            [source]="previewedSource"
                            class="d-block sub-content"
                            icon="broom-wide"
                            iconTitle="Clear"
                            (iconClicked)="previewedSource = null; previewedClip = null"
                            [titlePlaceholder]="'NO_PREVIEW_SELECTED' | translate"
                        ></app-source-details-wrapper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #loadingAnimation>
    <content-loading-animation></content-loading-animation>
</ng-template>
